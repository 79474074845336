export const apis = {
  adminLogin: '/fhjiot-admin/login',//后台用户登录
  wechatUserIndex: '/web/dashboard/index',//小程序用户登录首页数据
  deviceList: '/web/dashboard/getOnlineDeviceList',//设备列表
  fowerList: '/web/dashboard/getFowerList',//楼宇列表
  monitorList: '/web/dashboard/getMonitorList',//监控列表
  getYsToken: '/web/dashboard/getYsToken',//监控token
  adminUserIndex: '/fhjiot-admin/dev/devonlinedevice/dashboard',//后台用户登录首页数据
  adminDevstatisProvince: '/fhjiot-admin/statis/devstatisprovince/page',//省份数据
  adminMonitorList: '/fhjiot-admin/dev/monitordevice/list',//后台监控列表
  adminGetYsToken: '/fhjiot-admin/dev/devonlinedevice/getYsToken',//后台监控token
  todayEvent: '/web/dashboard/getAlarmHistoryList'//今日事件
}