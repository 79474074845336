import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/index.vue')
  },
  //楼宇
  {
    path: '/building',
    name: 'building',
    component: () => import('../views/building.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  }
]
const router = new VueRouter({
  mode: "history",
  routes
})

router.beforeEach((to, from, next) => {
  if (to.fullPath === '/login') {
    next();
  } else {
    if (localStorage.getItem('token') || to.query.token) {
      next();
    } else {
      next('/login');
    }
  }
});

export default router