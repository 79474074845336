import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import dataV from '@jiaminghi/data-view';
Vue.use(dataV);

// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/fire.js';
import 'vue-awesome/icons/align-justify.js';
import 'vue-awesome/icons/calendar.js';
import 'vue-awesome/icons/chevron-down.js'
import 'vue-awesome/icons/video.js'

// 全局注册图标
Vue.component('icon', Icon);

// 适配flex
import '@/common/flexible.js';

// 引入全局css
import './assets/scss/style.scss';


//引入echart
import echarts from 'echarts';
Vue.prototype.$echarts = echarts;

import { get, post, put, del, apis } from './services';
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$put = put;
Vue.prototype.$del = del;
Vue.prototype.$apis = apis;

import { Message, Input, Form, FormItem, Dialog} from 'element-ui';
Vue.prototype.$message = Message;
Vue.use(Input);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Dialog);


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
