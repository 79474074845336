import axios from 'axios';
import { Message } from 'element-ui';
import router from '@/router';

// 创建 axios 实例
let service = axios;

service.defaults.timeout = 60000;
// service.defaults.baseURL = baseUrl;
service.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8';
//允许携带cookiewithCredentials的情况下，后端要设置Access-Control-Allow-Origin为你的源地址，
//例如http://localhost:8080，不能是*，而且还要设置header(‘Access-Control-Allow-Credentials: true’),
service.defaults.withCredentials = true;
// 添加请求拦截器
service.interceptors.request.use(
  config => {
    if (config.method === 'post' || config.method === 'put') {
      // post、put 提交时，将对象转换为string, 为处理Java后台解析问题
      config.data = JSON.stringify(config.data);
    }
    if (localStorage.getItem('token')) {
      config.headers.token = localStorage.getItem('token');
    }
    // 请求发送前进行处理
    return config;
  },
  error => {
    // 请求错误处理
    return Promise.reject(error);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  (response) => {
    if (response.data.code === 0) {
      let { data } = response;
      return data;
    } else {
      switch (response.data.code) {
        case 10020:
        case 10021:
        case 401:
          Message({
            message: '登录过期，请重新登录',
            type: 'warning'
          });
          localStorage.removeItem('token');
          localStorage.removeItem('baseUrl');
          router.replace({path: '/login'});
          return;
        default:
          if (response.data.msg) {
            Message({
              message: response.data.msg,
              type: 'warning'
            });
          }
          return;
      }
    }
  },
  (error) => {
    let info = {};
    if (!error.response) {
      info = {
        code: 500,
        msg: 'Network Error'
      };
      Message.error('Network Error');
      return Promise.reject(info);
    } else {
      Message.error(`${error.response.statusText} code:${error.response.status}`);
      return Promise.reject(error.response);
    }
  }
);
function baseUrl() {
  let baseUrl = '';
  let baseUrlStorage = localStorage.getItem('baseUrl');
  if (process.env.NODE_ENV === 'development') {
    if (baseUrlStorage&&baseUrlStorage.indexOf('admin') !== -1) {
      baseUrl = '/admin';
    } else {
      baseUrl = '/api';
    }
  } else {
    baseUrl = baseUrlStorage;
  }
  return baseUrl;
}
/**
 * 创建统一封装过的 axios 实例
 * @return {AxiosInstance}
 */
export function get(url, params, headers, keepUrl=false) {
  let options = {};
  if (params) {
    options.params = params;
  }
  if (headers) {
    options.headers = headers;
  }
  return service.get(keepUrl ? url : baseUrl()+url, options);
}

export function post(url, data, headers, keepUrl=false) {
  let options = {};
  if (headers) {
    options.headers = headers;
  }
  return service.post(keepUrl ? url : baseUrl()+url, data, options);
}

export function put(url, data, headers, keepUrl=false) {
  let options = {};
  if (headers) {
    options.headers = headers;
  }
  return service.put(keepUrl ? url : baseUrl()+url, data, options);
}

export function del(url, params, headers, keepUrl=false) {
  let options = {};
  if (params) {
    options.params = params;
  }
  if (headers) {
    options.headers = headers;
  }
  return service.delete(keepUrl ? url : baseUrl()+url, options);
}